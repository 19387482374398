import * as React from "react"
import { Link } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"

import Seo from "../components/seo"
import "../components/main.css"

const IndexPage = () => (
    <>
        <Seo title="ホーム" />
        <div className="header">
            <h1>バーチャル授乳Cafeキタリナのホームページ</h1>
        </div>
        <div className="content">
            <div className="images">
                <StaticImage
                    src="../images/kitarina-poster-honten.jpg"
                    loading="eager"
                    quality={95}
                    formats={["auto", "webp", "avif"]}
                    alt=""
                    style={{ maxWidth: `350px` }}
                />
                <div className="text link">
                    <a href="https://www.youtube.com/channel/UCtxD6yGYG5UZTFWDfHX4PBw" target="_blank" rel="noreferrer">公式YouTubeチャンネルはこちら！</a>
                </div>
            </div>
            <div className="inner-content">
                <h1>キタリナってなに？</h1>
                <p className="text">バーチャル授乳Cafeキタリナとは、VR空間上に存在する、哺乳瓶を使って飲んだり飲ませたりする「癒やし」と「落ち着き」がコンセプトのCafeイベントです。</p>
                <hr />
                <h1>店舗</h1>
                <p className="description">参加方法は各店舗の個別ページを御覧ください！</p>
                <h2>VRChat</h2>
                <p className="text">PC本店 <Link to="/honten">店舗情報はこちら</Link></p>
                <p className="text">Quest支店 <Link to="/quest">店舗情報はこちら</Link></p>
                <h1>注意事項</h1>
                <div className="text">
                    <p>以下の行為はご遠慮頂いております。</p>
                    <ul>
                        <li>犯罪行為、公序良俗、各プラットフォーム規約に違反する行為</li>
                        <li>他のお客様のご迷惑になる行為</li>
                        <li>哺乳瓶以外から飲ませる授乳行為</li>
                        <li>許可のない動画撮影や配信</li>
                    </ul>
                </div>
                <hr />
                <p>
                    当サイトの内容、テキスト、画像等の無断転載・無断使用を固く禁じます。<br />
                    また、まとめサイト等への引用を厳禁致します。<br />
                    お問い合わせはDMまでご連絡お願い致します。
                </p>
            </div>
        </div>
        <div className="footer">
            <p>&copy; {new Date().getFullYear()} バーチャル授乳Cafeキタリナ</p>
        </div>
    </>
)

export default IndexPage
